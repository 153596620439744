import { useContext, useEffect, useState } from "react"
import { Sidebar, Header } from "./index"
import { Appstate } from "../App"
import { message } from "antd";
import { TailSpin } from "react-loader-spinner";
import { SocialIcon } from "react-social-icons";

const Wallet = () => {
  const useAppState = useContext(Appstate);
  const [data, setData] = useState({
    ROI: 0,
    directIncome: 0,
    royaltyIncome: 0,
    uplineIncome: 0,
    downlineIncome: 0,
    generationIncome: 0,
    totalIncome: 0,
    amtInRTR: 0
  })
  const [data2, setData2] = useState({
    ROI: 0,
    directIncome: 0,
    royaltyIncome: 0,
    uplineIncome: 0,
    downlineIncome: 0,
    generationIncome: 0,
    totalIncome: 0,
  })
  const [loading, setLoading] = useState(false);

  const withdraw = async () => {
    setLoading(true);
    try {
      let tx = await useAppState.contract.withdraw();
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Withdrawn");
    } catch(error) {
      message.error(error.reason)
    }
    setLoading(false);
  }

  useEffect(() => {
    async function req() {
      let _user = await useAppState.contract.incomeInfo(useAppState.walletAddress);
      let _rev = await useAppState.contract.revenueInfo(useAppState.walletAddress);
      let _amt = 0;
      if(Number(_user.totalIncome) > 0) {
        _amt = await useAppState.contract.callStatic.getQuoteInRTR(_user.totalIncome);
      }

      setData({
        ROI: useAppState.convert(_user.ROI),
        directIncome: useAppState.convert(_user.directIncome),
        royaltyIncome: useAppState.convert(_user.royaltyIncome),
        uplineIncome: useAppState.convert(_user.uplineIncome),
        downlineIncome: useAppState.convert(_user.downlineIncome),
        generationIncome: useAppState.convert(_user.generationIncome),
        totalIncome: useAppState.convert(_user.totalIncome),
        amtInRTR: useAppState.convert(_amt)
      })
      setData2({
        ROI: useAppState.convert(_rev.ROI),
        directIncome: useAppState.convert(_rev.directIncome),
        royaltyIncome: useAppState.convert(_rev.royaltyIncome),
        uplineIncome: useAppState.convert(_rev.uplineIncome),
        downlineIncome: useAppState.convert(_rev.downlineIncome),
        generationIncome: useAppState.convert(_rev.generationIncome),
        totalIncome: useAppState.convert(_rev.totalIncome),
      })
    }
    req();
  },[useAppState.walletAddress, useAppState.change])

  return (
    <>
    <div className='flex'>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='p-4 w-full md:w-4/5 relative mb-4'>
        <img src='/bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-100' />
        <img src='/bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />

          <div className='flex flex-col md:flex-row w-full mt-3'>
              <div className='w-full md:w-3/5 mt-4 md:mt-0'>
                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data2.ROI} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>ROI Income</span>
                    </p>
                    <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.ROI} USDT</span>
                      <span>Withdrawable </span>
                    </p>
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data2.directIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Direct Income</span>
                    </p>
                    <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.directIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p>
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data2.uplineIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Upline Income</span>
                    </p>
                    <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.uplineIncome} USDT</span>
                      <span>Withdrawable</span>
                    </p>
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data2.downlineIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Downline Dividend Income</span>
                    </p>
                    <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.downlineIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p>
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data2.generationIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Generation Income</span>
                    </p>
                    <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.generationIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p>
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data2.royaltyIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Royalty Income</span>
                    </p>
                    <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.royaltyIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p>
                  </p>
              </div>

              <div className='w-full md:w-2/5 ml-0 md:ml-6 mt-8 md:mt-0'>
                <p className='flex justify-center items-center flex-col px-4 py-3 rounded-l2 w-full relative'>
                  <img src="/greenborder.png" className="absolute w-full top-0 left-0" />
                  <span className='text-sm font-semibold mt-3'><span className="text-green-500">Available</span> Withdrawl</span>
                  <span className="text-xl font-bold flex items-center">${data.totalIncome.toFixed(2)} <img src="/usdt.png" className="h-4 ml-2 mr-2" /> | {data.amtInRTR.toFixed(2)} <img src="/rtr.png" className="h-5 ml-2" /></span>
                </p>
                <div className='mt-12 w-full flex justify-center'>
                  <button className='px-14 py-4 text-sm font-semibold rounded-md renew-btn'>Cancel</button>
                  <button onClick={withdraw} className='ml-4 px-14 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black'>{loading ? <TailSpin height={12} color="black" /> : 'Confirm'}</button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    </> 
  ) 
} 

export default Wallet