import React, { useContext } from 'react'
import { Appstate } from '../App'
import { Link } from 'react-router-dom';

const Header = () => {
  const useAppState = useContext(Appstate);

  return (
    <div className='h-16 flex md:justify-end z-20 justify-between bg-opacity-80 bg-[#151516] items-center w-full md:w-4/5 sticky top-0 py-2 px-3 shadow-md'>
      <Link to={'/'}><h1 className='font-bold text-2xl font-mono visible md:hidden text-white flex justify-center items-center'><img src='rtr.png' className='h-12 mr-2' /> Rector <p className='text-xs text-lime-500 font-mono ml-2'>~ ${useAppState.price}</p></h1></Link>
      <h1 className='font-bold text-3xl font-mono text-white'>
      <div onClick={useAppState.toggle} class={`${useAppState.show ? "open" : ""} wrapper-menu md:hidden block`}>
        <div class="line-menu half start"></div>
        <div class="line-menu"></div>
        <div class="line-menu half end"></div>
      </div>
      </h1>
    </div>
  )
}

export default Header
// <span className={`${useAppState.show ? "ham3" : "ham"}`}></span>