import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Appstate } from "../App";
import {TailSpin} from 'react-loader-spinner'

const TeamUsers = () => {
  const useAppState = useContext(Appstate);
  const [layer, setLayer] = useState(0);
  const [users, setUsers] = useState([{
    sno: 0,
    address: "",
    rank: 0,
    package: 0,
    totalBusiness: 0,
    start: 0
  }]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      let data = await useAppState.contract.getTeamUsers(useAppState.walletAddress, layer);
      let arr = [];
      for(let i=0; i<data.length; i++) {
        arr.push({
          sno: i+1,
          address: data[i].account,
          rank: Number(data[i].rank),
          package: useAppState.convert(data[i].package),
          totalBusiness: useAppState.convert(data[i].totalBusiness),
          start: Number(data[i].start) * 1000
        })
      }
      setUsers(arr);
      setLoading(false);
    }
    getData();
  },[useAppState.change, useAppState.walletAddress, layer])

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full flex flex-col items-end">
        <Header />
        <div className="md:p-4 p-2 w-full md:w-4/5 min-h-screen">
          <img src="../bgelem.png" className="fixed hidden md:block right-0 top-0 -z-10 opacity-90"/>
          <img src="../bgmobelem.png" className="fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100"/>

          <h1 className="font-bold text-2xl mt-3 px-2 md:px-0">
            My <span className="text-violet-500">Teams</span>
          </h1>

          <div className="flex mt-3 overflow-x-auto">
            <a onClick={() => setLayer(0)} className={`cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 0 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>1</a>
            <a onClick={() => setLayer(1)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 1 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>2</a>
            <a onClick={() => setLayer(2)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 2 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>3</a>
            <a onClick={() => setLayer(3)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 3 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>4</a>
            <a onClick={() => setLayer(4)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 4 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>5</a>
            <a onClick={() => setLayer(5)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 5 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>6</a>
            <a onClick={() => setLayer(6)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 6 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>7</a>
            <a onClick={() => setLayer(7)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 7 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>8</a>
            <a onClick={() => setLayer(8)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 8 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>9</a>
            <a onClick={() => setLayer(9)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 9 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>10</a>
            <a onClick={() => setLayer(10)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 10 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>11</a>
            <a onClick={() => setLayer(11)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 11 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>12</a>
            <a onClick={() => setLayer(12)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 12 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>13</a>
            <a onClick={() => setLayer(13)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 13 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>14</a>
            <a onClick={() => setLayer(14)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 14 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>15</a>
            <a onClick={() => setLayer(15)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 15 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>16</a>
            <a onClick={() => setLayer(16)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 16 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>17</a>
            <a onClick={() => setLayer(17)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 17 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>18</a>
            <a onClick={() => setLayer(18)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 18 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>19</a>
            <a onClick={() => setLayer(19)} className={`ml-2 cursor-pointer font-medium px-4 mt-2 py-2 rounded-sm ${layer == 19 ? "bg-green-500" : "bg-gray-700 bg-opacity-25"} ml-0`}>20</a>
          </div>

          <div className="mt-6 w-full">
            {loading ? (
              <div className="mt-6 flex justify-center items-center">
                <TailSpin color="white" height={30} />
              </div>
            ) : (
            users.map((e, i) => {
              return (
                <div className="w-full mt-4" key={i}>
                  <div className='flex flex-col items-center py-4 relative rounded-xl h-[300px] px-4 w-full'>
                    <img src='../p-background.png' className='absolute top-0 -z-30 left-0 h-full w-full' />
                    {/* <img src={`../${useAppState.lvlName[e.rank].toLowerCase()}.png`} className='h-32' /> */}
                    {/* <p className='text-lime-500 font-semibold text-lg font-serif'>{useAppState.lvlName[e.rank]}</p> */}
                    <div className='flex w-full mt-4 font-semibold justify-between'>
                      <p>SNo.</p>
                      <p className=' px-2 rounded-full bg-blue-500 text-white'>{e.sno}</p>
                    </div>
                    <div className='flex w-full mt-4 font-semibold justify-between'>
                      <p>Address</p>
                      <p className='text-lime-500'>{e.address.slice(0, 8)}...{e.address.slice(37)}</p>
                    </div>
                    <div className='flex w-full mt-4 font-semibold justify-between'>
                      <p>Rank</p>
                      <p className='text-lime-500 flex items-center'>{useAppState.lvlName[e.rank]} <img src={`../${useAppState.lvlName[e.rank].toLowerCase()}.png`} className='h-6 ml-2' /></p>
                    </div>
                    <div className='flex w-full mt-4 font-semibold justify-between'>
                      <p>Package</p>
                      <p className='text-lime-500'>${e.package}</p>
                    </div>
                    <div className='flex w-full mt-4 font-semibold justify-between'>
                      <p>Total Business</p>
                      <p className='text-lime-500'>${e.totalBusiness}</p>
                    </div>
                    <div className='flex w-full mt-4 font-semibold justify-between'>
                      <p>Started</p>
                      <p className='text-lime-500'>{Number(e.start) > 0 ? new Date(e.start).toLocaleString() : "..."}</p>
                    </div>
                  </div>
                </div>
            )}))}
          </div>

          {/* <div id="last-users" className="mt-6 w-full">
              <div class="overflow-x-scroll w-full">
                {loading ? (
                  <div className="mt-6 flex justify-center items-center">
                    <TailSpin color="white" height={30} />
                  </div>
                ) : (
                  <table class="w-full whitespace-nowrap">
                    <thead class="bg-[#8080821a]">
                      <th class="text-left py-3 px-2 rounded-l-lg">SNo.</th>
                      <th class="text-left py-3 px-2">Address</th>
                      <th class="text-left py-3 px-2">Package</th>
                      <th class="text-left py-3 px-2">Rank</th>
                      <th class="text-left py-3 px-2">Total Business</th>
                      <th class="text-left py-3 px-2">Started</th>
                    </thead>
                    {users.map((e, i) => {
                      return (
                        <tr key={i} class="border-b border-gray-700">
                          <td class="py-3 px-2 font-bold">
                            <div class="inline-flex space-x-3 items-center">
                              <div
                                className={`rounded-full bg-blue-500 flex justify-center items-center h-10 w-10 font-semibold`}
                              >
                                {e.sno}
                              </div>
                            </div>
                          </td>

                          <td class="py-3 px-2">
                            <p>{e.address.slice(0, 8)}...{e.address.slice(37)}</p>
                          </td>

                          <td class="py-3 px-2">
                            <p>${e.package}</p>
                          </td>

                          <td class="py-3 px-2">
                            <p>{useAppState.lvlName[e.rank]}</p>
                          </td>

                          <td class="py-3 px-2">
                            <p>${e.totalBusiness}</p>
                          </td>
                          
                          <td class="py-3 px-2">
                            {Number(e.start) > 0 ? new Date(e.start).toLocaleString() : "..."}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                )}
              </div>
            </div> */}

        </div>
      </div>
    </div>
  );
};

export default TeamUsers;
